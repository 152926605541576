@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

@layer base {
    body {
        font-family: 'Montserrat';
        font-size: large;
}
    li {
        @apply px-4;
        @apply cursor-pointer;
    }

    p {
        @apply text-white;
    }
}